<template>
  <div class="home" style="height: 100%">
    <v-container style="height: 70%" align-center justify-center>
      <v-row>
        <v-col cols="3" />
        <v-col cols="6"
          ><strong>{{ $t("customer.title") }}</strong></v-col
        >
        <v-col cols="3" />
      </v-row>
      <v-row>
        <v-col cols="3" />
        <v-col cols="5" style="display: flex; align-items: center">
          <v-text-field
            v-model="email"
            :label="$t('customer.mail')"
            :readonly="!editMail"
            :filled="!editMail"
            style="height: 70%"
          ></v-text-field> </v-col
        ><v-col cols="1" style="display: flex; align-items: center"
          ><v-btn
            style="height: 70%"
            :disabled="editPhone"
            @click="
              editMail = !editMail;
              input = email;
              commitEmail();
            "
            ><v-icon v-if="!editMail">mdi-pencil</v-icon
            ><v-icon v-else>mdi-check</v-icon></v-btn
          ></v-col
        >
        <v-col cols="3" />
      </v-row>
      <v-row>
        <v-col cols="3" />
        <v-col cols="5" style="display: flex; align-items: center">
          <v-text-field
            v-model="phone"
            :label="$t('customer.phone')"
            style="height: 70%"
            :readonly="!editPhone"
            :filled="!editPhone"
          ></v-text-field> </v-col
        ><v-col cols="1" style="display: flex; align-items: center"
          ><v-btn
            style="height: 70%"
            :disabled="editMail"
            @click="
              editPhone = !editPhone;
              input = phone;
              commitPhone();
            "
            ><v-icon v-if="!editPhone">mdi-pencil</v-icon
            ><v-icon v-else>mdi-check</v-icon></v-btn
          ></v-col
        >
        <v-col cols="3" />
      </v-row>
      <v-row>
        <v-col cols="3" />
        <v-col cols="6"
          ><p v-if="!emailValid" style="color: #ff0000">
            <strong>{{ $t("customer.invalidMail") }}</strong>
          </p>
          <p v-if="!phoneValid" style="color: #ff0000">
            <strong>{{ $t("customer.invalidPhone") }}</strong>
          </p>
        </v-col>
        <v-col cols="3" />
      </v-row>
    </v-container>
    <v-footer
      class="justify-center"
      style="position: absolute; bottom: 50px; width: 100%"
    >
      <SimpleKeyboard
        v-show="editMail || editPhone"
        :input="input"
        @onChange="onChange"
      />
    </v-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import SimpleKeyboard from "@/components/SimpleKeyboard";
import axios from "axios";
export default {
  name: "CustomerInfo",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      email: this.$store.state.customerEmail,
      phone: this.$store.state.customerPhone,
      input: "",
      editMail: false,
      editPhone: false,
      emailValid: false,
      phoneValid: false,
    };
  },
  mounted() {
    if (this.$store.state.apptId == 0) this.$router.push("/");
    this.validate();
  },
  methods: {
    onChange(input) {
      let cb_conf = this.$store.state.callback;
      if (this.$store.state.hasMotors) axios.get("http://localhost:3000/front");
      else {
        if (this.$store.state.timeout !== "not yet")
          window.clearTimeout(this.$store.state.timeout);
        let timeout = window.setTimeout(() => {
          window.location.href = cb_conf;
        }, process.env.VUE_APP_NOMOTOR_TIMEOUT);
        this.$store.commit({ type: "timeout", timeout: timeout });
      }
      if (this.editMail) {
        this.email = input;
      } else if (this.editPhone) {
        this.phone = input;
      }
    },
    onInputChange(input) {
      let cb_conf = this.$store.state.callback;
      if (this.$store.state.hasMotors) axios.get("http://localhost:3000/front");
      else {
        if (this.$store.state.timeout !== "not yet")
          window.clearTimeout(this.$store.state.timeout);
        let timeout = window.setTimeout(() => {
          window.location.href = cb_conf;
        }, process.env.VUE_APP_NOMOTOR_TIMEOUT);
        this.$store.commit({ type: "timeout", timeout: timeout });
      }
      this.input = input.target.value;
    },
    commitEmail() {
      if (!this.editMail) {
        this.$store.commit("customerEmail", { email: this.email });
        this.validate();
      }
    },
    commitPhone() {
      if (!this.editPhone) {
        this.$store.commit("customerPhone", { phone: this.phone });
        this.validate();
      }
    },
    validate() {
      let regMail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      this.emailValid =
        this.email == "" ? false : regMail.test(this.email) ? true : false;
      let regPhone = /^\+?[0-9]+/;
      this.phoneValid =
        this.phone == "" ? false : regPhone.test(this.phone) ? true : false;
      if (this.emailValid && this.phoneValid) {
        this.$store.commit("enableNext");
      } else {
        this.$store.commit("disableNext");
      }
    },
  },
};
</script>
